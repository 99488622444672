/**
 * @generated SignedSource<<c1ed03dc9cf30414caa33aaa01764494>>
 * @relayHash 7e48c5a49a7ee8c66d6fa4b0b0fbe581
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID de9f83d75038a72663e18b7b46ac0ed6e5e5db8afcdfd5af17b9c90d665354fd

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAttendeeStatus = "ATTENDING_IN_PERSON" | "ATTENDING_VIRTUAL" | "NOT_ATTENDING" | "WAITING_IN_PERSON" | "WAITING_VIRTUAL" | "%future added value";
export type AttendEventInput = {
  clientMutationId?: string | null | undefined;
  eventId?: String | null | undefined;
  status?: EventAttendeeStatus | null | undefined;
};
export type EventAttendButtonHybridAttendMutation$variables = {
  input: AttendEventInput;
};
export type EventAttendButtonHybridAttendMutation$data = {
  readonly attendEvent: {
    readonly event: {
      readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_event">;
    } | null | undefined;
  } | null | undefined;
};
export type EventAttendButtonHybridAttendMutation = {
  response: EventAttendButtonHybridAttendMutation$data;
  variables: EventAttendButtonHybridAttendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slugId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailMd5",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryPhoto",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v15 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v3/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v4/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v17 = [
  (v14/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAttendButtonHybridAttendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AttendEventPayload",
        "kind": "LinkedField",
        "name": "attendEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventItemNew_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventAttendButtonHybridAttendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AttendEventPayload",
        "kind": "LinkedField",
        "name": "attendEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prettyUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasExternalUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasVenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "coverPhoto",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": "eventOwner",
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "primaryPhoto",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "type": "Guild",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "type": "User",
                    "abstractKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "attendeesByRelevance",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": {
                        "in": [
                          "ATTENDING_IN_PERSON",
                          "ATTENDING_VIRTUAL",
                          "WAITING_IN_PERSON",
                          "WAITING_VIRTUAL"
                        ]
                      }
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 8
                  }
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventAttendeesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventAttendee",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:8)"
              },
              {
                "alias": "attendingCount",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": {
                        "in": [
                          "ATTENDING_IN_PERSON",
                          "ATTENDING_VIRTUAL"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendees",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\"]}})"
              },
              {
                "alias": "waitingCount",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": {
                        "in": [
                          "WAITING_IN_PERSON",
                          "WAITING_VIRTUAL"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendees",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventTicketingTiersConnection",
                "kind": "LinkedField",
                "name": "eventTicketingTiers",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventAttendee",
                "kind": "LinkedField",
                "name": "myAttendance",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventAttendeeTicketPurchase",
                    "kind": "LinkedField",
                    "name": "ticketPurchase",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripePaymentIntentStatus",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuildMembership",
                        "kind": "LinkedField",
                        "name": "myMembership",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "membershipLevel",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Guild",
                    "abstractKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": "eventItemPresentations",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "CREATED_AT_ASC",
                      "ID_ASC"
                    ]
                  }
                ],
                "concreteType": "PresentationsConnection",
                "kind": "LinkedField",
                "name": "presentations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PresentationsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Presentation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "presenterFirstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "presenterLastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "presenter",
                            "plural": false,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "presentations(orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"])"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "de9f83d75038a72663e18b7b46ac0ed6e5e5db8afcdfd5af17b9c90d665354fd",
    "metadata": {},
    "name": "EventAttendButtonHybridAttendMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b3542d2176217b2e64375a477296fc21";

export default node;
