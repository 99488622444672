import React, { useMemo, useCallback } from 'react'
import { useFragment, graphql } from 'react-relay'
import type { GestureResponderEvent } from 'react-native'

// import { EmojiIcon } from '../../components/EmojiIcon/EmojiIcon'
import { Icon } from '../Icon'
import { Button } from '../Button/Button'

import { EventAttendButtonButton_event$key } from './__generated__/EventAttendButtonButton_event.graphql'

const BUTTON_HEIGHT = 30

export type BUTTON_ACTIONS =
  | 'Attend'
  | 'Attending'
  | 'Waiting'
  | 'Checkout'
  | 'Next Time'

const BUTTON_ATTENDANCE_ICONS: Record<BUTTON_ACTIONS, React.ReactNode> = {
  Attending: <Icon key='attending' name='checkmark' color='$grey9' size='$4' />,
  Attend: (
    <Icon key='notAttending' name='chevron-right' color='$grey0' size='$4' />
  ),
  Waiting: <Icon key='waiting' name='clock' color='$grey9' size='$4' />,
  Checkout: (
    // <EmojiIcon size={4} name='admission-tickets' />

    <Icon
      key='waiting'
      name='ticket-diagonal-filled'
      color='$grey0'
      size='$4'
      //   rotate='-45deg'
    />
  ),
  'Next Time': null,
}

export const EventAttendButtonButton = Button.styleable<{
  event: EventAttendButtonButton_event$key
}>(({ event, onPress, ...props }, ref) => {
  const data = useFragment(
    graphql`
      fragment EventAttendButtonButton_event on Event {
        id

        endAt

        myAttendance {
          status

          ticketPurchase {
            status
          }
        }

        eventTicketingTiers {
          totalCount
        }
      }
    `,
    event
  )

  const eventInPast = useMemo(() => {
    if (!data.endAt) {
      return false
    }

    return new Date().getTime() > new Date(data.endAt as string).getTime()
  }, [data.endAt])

  const buttonAction = useMemo<BUTTON_ACTIONS>(() => {
    if (eventInPast) {
      return 'Next Time'
    }

    if (
      data.myAttendance?.status &&
      ['ATTENDING_IN_PERSON', 'ATTENDING_VIRTUAL'].includes(
        data.myAttendance.status
      )
    ) {
      return 'Attending'
    }

    if (
      data.eventTicketingTiers.totalCount &&
      data.myAttendance?.ticketPurchase?.status !== 'SUCCEEDED'
    ) {
      return 'Checkout'
    }

    if (!data.myAttendance || data.myAttendance.status === 'NOT_ATTENDING') {
      return 'Attend'
    }

    return 'Waiting'
  }, [data, eventInPast])

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (onPress) {
        return onPress(event)
      }
    },
    [onPress]
  )

  return (
    <Button
      ref={ref}
      {...props}
      borderRadius={14}
      secondary={!['Attend', 'Checkout'].includes(buttonAction)}
      onPress={handlePress}
      gap='$1'
      paddingHorizontal='$2'
      height={BUTTON_HEIGHT}
      minHeight={BUTTON_HEIGHT}
    >
      {BUTTON_ATTENDANCE_ICONS[buttonAction]}

      <Button.Text
        size='$4'
        marginLeft={BUTTON_ATTENDANCE_ICONS[buttonAction] ? 0 : '$1'}
        marginRight='$1'
      >
        {buttonAction}
      </Button.Text>
    </Button>
  )
})
